<template>
    <pagination title="Departments" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :entities="departments">
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
        </template>
        <template v-slot:items>
            <template v-if="departments && departments.size > 0">
                <tr v-if="departments.size > 0" v-for="department in departments">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                        {{department.entity.properties.get('name')}}<br>
                    </td>
    <!--                <td class="whitespace-nowrap px-3 py-4 text-sm text-v3-gray-800"></td>-->
                </tr>
            </template>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";

export default {
  name: 'HrCompanyDepartmentsPagination',
    components: {Pagination},
  props: {
    departments: {},
      collapsable:{
        type: Boolean,
          default: false
      },
      open:{
        type:Boolean,
          default: false
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
