<template>
    <pagination title="Contracts" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :entities="contracts">
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Prefix
            </th>
<!--            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-v3-gray-600">Status</th>-->
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Name</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Address Override</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Belongs To User</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Belongs To Project</th>
<!--            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-v3-gray-600">Company</th>-->
        </template>
        <template v-slot:items>
            <template v-if=" contracts && contracts.size > 0">
                <tr v-if="contracts.size > 0" v-for="contract in contracts">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                        {{contract.entity.properties.get('prefix')}}
                    </td>
    <!--                <td class="whitespace-nowrap px-3 py-4 text-sm text-v3-gray-800">On Site</td>-->
                    <td class="whitespace-normal px-3 py-4 text-sm">
                        {{contract.entity.properties.get('name')}}
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm">
                        {{contract.entity.properties.get('address')}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        {{contract.entity.properties.get('belongs_to_user')}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                        {{contract.entity.properties.get('belongs_to_project')}}
                    </td>
    <!--                <td class="whitespace-nowrap px-3 py-4 text-sm text-v3-gray-800"></td>-->
                </tr>
            </template>
            <tr v-else>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-center" colspan="5">
                    No contracts found.
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";

export default {
  name: 'HrCompanyContractsPagination',
    components: {Pagination},
  props: {
    contracts: {},
      collapsable: {
        type: Boolean,
          default: false
      },
      open:{
        type: Boolean,
          default: false
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
