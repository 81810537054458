<template>
    <pagination title="Projects" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :entities="projects">
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Address</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Note</th>
        </template>
        <template v-slot:items>
            <template v-if="projects && projects.size > 0">
                <tr v-if="projects.size > 0" v-for="project in projects">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                        {{project.entity.properties.get('name')}}
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm">
                        {{project.entity.properties.get('address')}}
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm">
                        {{project.entity.properties.get('note')}}
                    </td>
                </tr>
            </template>
            <tr v-else>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-center" colspan="5">
                    No projects found.
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";

export default {
  name: 'HrCompanyProjectsPagination',
    components: {Pagination},
  props: {
    projects: {},
      collapsable: {
        type: Boolean,
          default: false
      },
      open:{
        type: Boolean,
          default: false
      }
  },
    methods: {
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
